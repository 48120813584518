<template>
    <div>
        <template v-if="ready">
            <e-editor-froala
                :identifier="z_id"
                :placeholder="placeholder"
                :content="content"
                @change="updateEditor"
                :variables.sync="variables"
                :blocks.sync="blocks"
                :moreText="more_text"
            ></e-editor-froala>
        </template>
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import Navigation from '@/mixins/Navigation.js'
    import Model from '@/mixins/Model.js'

	export default {
		name: "LongtextInput",
        mixins: [Navigation, Model],
        props:[
            'placeholder',
            'z_id',
            'content',
            'required',
            'variable_type'
        ],
        model: {
            prop: 'checked',
            event: 'change'
        },
        data() {
            return {
                ready: false,
                input_text: '',
                variables: [],
                blocks: [],
                events_tab: {
                    'inputs::resetChecked': this.resetCheck
                },
                more_text: []
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				let res = await this.getVariables(this.variable_type)

				for (let i = 0; i < res.variables.length; i++) {
                    this.variables.push({
						id: res.variables[i].modeltypevariables_key,
						name: res.variables[i].modeltypevariables_label,
                    })
				}

				this.blocks = this.formatBlocks(res.blocks)

                const zone = await this.getModelZone(this.z_id)
                if(zone && zone.zonetype && zone.zonetype.modelzonetype_code === 'subject_courrier_contrat_document') {
                    this.more_text = ['variable']
                }

                this.ready = true
			},
			formatBlocks(blocks) {
				const formattedBlocks = []

				for (let i = 0; i < blocks.length; i++) {
					let block = {
						id: blocks[i].modeltypeblock_blockname,
						name: blocks[i].modeltypeblock_label,
						variables: []
					}

					for (let j = 0; j < blocks[i].variables.length; j++) {
						block.variables.push({
							id: blocks[i].variables[j].modeltypevariables_key,
							name: blocks[i].variables[j].modeltypevariables_label,
						})
					}

					block.children = this.formatBlocks(blocks[i].children)

					formattedBlocks.push(block)
				}

				return formattedBlocks
			},
            resetCheck() {
                this.input_text = ''
            },
            updateEditor(val){
                this.$emit('change', val)
            },
        }
    }
</script>
